import { CONSTANTS } from "./constants";
import { BreakingNews, BreadCrumbs, BreadCrumbsAmp, GridView, ListView, Footer, FooterAmp, Header, HeaderAmp, HeroLayout, HeroLayout2, AuthorShow, PageNotFound, VideoShow, ArticleShow, SlideShow, StaticPage, WebStories, ArticleShowAmp,BreakingNewsTwo,QuickViewTwo,ListViewTwo,HomeGridViewTwo,WebStoriesTwo,HeroLayoutTwo,HeroLayoutHrTwo,RhsHomeTwo,GridViewTwo,WebStoriesListTwo,MediaListTwo,PhotoListTwo,ArticleShowTwo,VideoShowTwo,SlideShowTwo,FooterTwo,HeaderTwo,BreadCrumbsTwo,AuthorShowTwo,StaticPageTwo,PageNotFoundTwo,RhsHomeOtherTwo,ArticleShowTwoAmp,HeaderTwoAmp,FooterTwoAmp,BreadCrumbsTwoAmp,PhotoShowTwoAmp,VideoShowTwoAmp,BreakingNewsTicker,PodcastTwo, BreakingNewsThreeTicker,QuickViewThree, ListViewThree, HomeGridViewThree, WebStoriesThree, WebStoriesListThree, HeroLayoutThree, RhsHomeThree, RhsHomeOtherThree, GridViewThree, MediaListThree, PhotoListThree, ArticleShowThree, VideoShowThree, PodcastThree, SlideShowThree, FooterThree, HeaderThree, BreadCrumbsThree, AuthorShowThree, StaticPageThree, PageNotFoundThree, ArticleShowThreeAmp, HeaderThreeAmp, FooterThreeAmp, BreadCrumbsThreeAmp, PhotoShowThreeAmp, VideoShowThreeAmp,HeroLayoutwithHeader, FourLinks, HomeWebStories, HomeFaq,FaqList,OtherListView,Experts,QuizShow,SurveyShow,AddScripts  } from 'm360-react-components';
// import ScriptsComponent from "../components/ScriptsComponent/ScriptsComponent.js";
// import SlideShow from "../components/SlideShow/SlideShow.js";
// import AddScripts from "../components/AddScripts/AddScripts.js";
import AddScriptsTwo from "../components/AddScriptsTwo/AddScriptsTwo.js";
import AddScriptsThree from "../components/AddScriptsThree/AddScriptsThree.js";
import createComponentJson from "./component-list.js";
import { getSessionObject } from "./Utils.js";

// import StaticPage from "../components/StaticPage/StaticComponent";


export const theme_components = [ 

  {
    themeId: [3],
    component: Header,
    uniqueName: "Header",
    displayName: "Header"
  },
  // {
  //   themeId: [4],
  //   component: Header,
  //   uniqueName: "Header",
  //   displayName: "Header 2"
  // },
  {
    themeId: [3],
    component: ListView,
    uniqueName: "List View",
    displayName: "List View"
  },
  // {
  //   themeId: [4],
  //   component: BreakingNews,
  //   uniqueName: "Breaking News",
  //   displayName: "Breaking News 19"
  // },
  {
    themeId: [3],
    component: BreakingNews,
    uniqueName: "Breaking News",
    displayName: "Breaking News"
  },
  {
    themeId: [4],
    component: BreakingNewsTwo,
    uniqueName: "Breaking News Two",
    displayName: "Trending News Carousel"
  },
  {
    themeId: [4],
    component: BreakingNewsTicker,
    uniqueName: "Breaking News Ticker",
    displayName: "Breaking News Ticker"
  },
  {
    themeId: [4],
    component: QuickViewTwo,
    uniqueName: "Quick View Two",
    displayName: "Landscape Card Carousel"
  },
  {
    themeId: [4],
    component: ListViewTwo,
    uniqueName: "List View Two",
    displayName: "List View"
  },
  {
    themeId: [4],
    component: HomeGridViewTwo,
    uniqueName: "Home Grid View Two",
    displayName: "Home Grid View"
  },
  {
    themeId: [4],
    component: WebStoriesTwo,
    uniqueName: "WebStories Two",
    displayName: "Webstory Card Carousel"
  },
  {
    themeId: [4],
    component: WebStoriesListTwo,
    uniqueName: "WebStoriesList Two",
    displayName: "WebStories List"
  },

  {
    themeId: [4],
    component: HeroLayoutTwo,
    uniqueName: "HeroLayout Two",
    displayName: "HeroLayout"
  },
  {
    themeId: [4],
    component: HeroLayoutHrTwo,
    uniqueName: "HeroLayout Hr Two",
    displayName: "HeroLayout Hr"
  },
  {
    themeId: [4],
    component: RhsHomeTwo,
    uniqueName: "RhsHome Two",
    displayName: "RHS Layout"
  },
  {
    themeId: [4],
    component: RhsHomeOtherTwo,
    uniqueName: "RhsHome Other Two",
    displayName: "RHS Layout Other"
  },
  {
    themeId: [4],
    component: GridViewTwo,
    uniqueName: "GridView Two",
    displayName: "GridView Media"
  },
  // {
  //   themeId: [4],
  //   component: GridViewTwo,
  //   uniqueName: "GridView Other Two",
  //   displayName: "GridView Other"
  // },
  {
    themeId: [4],
    component: MediaListTwo,
    uniqueName: "MediaList Two",
    displayName: "MediaList"
  },
  {
    themeId: [4],
    component: PhotoListTwo,
    uniqueName: "PhotoList Two",
    displayName: "PhotoList"
  },
  {
    themeId: [4],
    component: ArticleShowTwo,
    uniqueName: "Article Show Two",
    displayName: "Article Show"
  },
  {
    themeId: [4],
    component: VideoShowTwo,
    uniqueName: "Video Show Two",
    displayName: "Video Show"
  },
  {
    themeId: [19,4],
    component: PodcastTwo,
    uniqueName: "Podcast Two",
    displayName: "Podcast"
  },
  {
    themeId: [19,4],
    component: SlideShowTwo,
    uniqueName: "Slide Show Two",
    displayName: "Slide Show"
  },
  {
    themeId: [4],
    component: FooterTwo,
    uniqueName: "Footer Two",
    displayName: "Footer"
  },
  {
    themeId: [4],
    component: HeaderTwo,
    uniqueName: "Header Two",
    displayName: "Header"
  },

  {
    themeId: [4],
    component: BreadCrumbsTwo,
    uniqueName: "BreadCrumbs Two",
    displayName: "BreadCrumbs"
  },
  {
    themeId: [4],
    component: AddScripts,
    uniqueName: "Ad Scripts Two",
    displayName: "Ad Scripts Two"
  },
  {
    themeId: [3],
    component: ArticleShow,
    uniqueName: "Article Show",
    displayName: "Article Show"
  },
  {
    themeId: [3],
    component: GridView,
    uniqueName: "Grid View",
    displayName: "Grid View"
  },
  {
    themeId: [3],
    component: GridView,
    uniqueName: "Grid View2",
    displayName: "Grid View Other"
  },
  {
    themeId: [3],
    component: Footer,
    uniqueName: "Footer",
    displayName: "Footer"
  },
  {
    themeId: [3],
    component: ListView,
    uniqueName: "Similar Stories",
    displayName: "Similar Stories"
  },
  {
    themeId: [3],
    component: BreadCrumbs,
    uniqueName: "Bread Crumbs",
    displayName: "Bread Crumbs"
  },
  {
    themeId: [3],
    component: GridView,
    uniqueName: "Carousel Card Block",
    displayName: "Carousel Card Block"
  },
  {
    themeId: [3],
    component: GridView,
    uniqueName: "Circular Card Carousel",
    displayName: "Circular Card Carousel"
  },
  {
    themeId: [3],
    component: HeroLayout,
    uniqueName: "Hero Layout",
    displayName: "Hero Layout"
  },
  {
    themeId: [3],
    component: HeroLayout2,
    uniqueName: "Hero Layout2",
    displayName: "Hero Layout2"
  },
  {
    themeId: [3],
    component: AuthorShow,
    uniqueName: "Author Show",
    displayName: "Author Show"
  },
  {
    themeId: [4],
    component: AuthorShowTwo,
    uniqueName: "Author Show Two",
    displayName: "Author Show"
  },
  {
    themeId: [4],
    component: StaticPageTwo,
    uniqueName: "Static Page Two",
    displayName: "Static Page"
  },
  {
    themeId: [4],
    component: PageNotFoundTwo,
    uniqueName: "Page Not Found Two",
    displayName: "PageNotFound"
  },

  {
    themeId: [4],
    component: ArticleShowTwoAmp,
    uniqueName: "Article Show Two Amp",
    displayName: "ArticleShow"
  },
  {
    themeId: [4],
    component: HeaderTwoAmp,
    uniqueName: "Header Two Amp",
    displayName: "Header"
  },
  {
    themeId: [4],
    component: FooterTwoAmp,
    uniqueName: "Footer Two Amp",
    displayName: "Footer"
  },
  {
    themeId: [4],
    component: BreadCrumbsTwoAmp,
    uniqueName: "Bread Crumbs Two Amp",
    displayName: "Bread Crumbs"
  },
  {
    themeId: [4],
    component: PhotoShowTwoAmp,
    uniqueName: "Photo Show Two Amp",
    displayName: "Photo Show"
  },
  {
    themeId: [4],
    component: VideoShowTwoAmp,
    uniqueName: "Video Show Two Amp",
    displayName: "Video Show"
  },

  

  {
    themeId: [3],
    component: VideoShow,
    uniqueName: "Video Show",
    displayName: "Video Show"
  },
  {
    themeId: [3],
    component: PageNotFound,
    uniqueName: "Page Not Found",
    displayName: "Page Not Found"
  },
  {
    themeId: [3],
    component: AddScripts,
    uniqueName: "Ad Scripts",
    displayName: "Ad Scripts New"
  },
  {
    themeId: [3],
    component: AddScripts,
    uniqueName: "Ad Scripts Amp",
    displayName: "Ad Scripts Amp"
  },
  {
    themeId: [3],
    component: SlideShow,
    uniqueName: "Slide Show",
    displayName: "Slide Show"
  },
  {
    themeId: [3],
    component: StaticPage,
    uniqueName: "Static Page",
    displayName: "Static Page"
  },
  {
    themeId: [3],
    component: HeaderAmp,
    uniqueName: "Header Amp",
    displayName: "Header Amp"
  },
  {
    themeId: [3],
    component: FooterAmp,
    uniqueName: "Footer Amp",
    displayName: "Footer Amp"
  },
  {
    themeId: [3],
    component: BreadCrumbsAmp,
    uniqueName: "Bread Crumbs Amp",
    displayName: "Bread Crumbs Amp" 
  },
  {
    themeId: [3],
    component: WebStories,
    uniqueName: "Web Stories",
    displayName: "Web Stories"
  },
  {
    themeId: [3],
    component: WebStories,
    uniqueName: "Web Stories2",
    displayName: "Web Stories2"
  },
  {
    themeId: [3],
    component: ArticleShowAmp,
    uniqueName: "Article Show Amp",
    displayName: "Article Show Amp"
  },
  {
    themeId: [3],
    component: VideoShowTwoAmp,
    uniqueName: "Video Show Two Amp",
    displayName: "Video Show"
  },


    // {
    //   themeId: [5],
    //   component: BreakingNewsThree,
    //   uniqueName: "Breaking News Three",
    //   displayName: "Breaking News"
    // },

    {
      themeId: [5],
      component: FourLinks,
      uniqueName: "Four Links",
      displayName: "Four Links"
    },

    {
      themeId: [5],
      component: HomeFaq,
      uniqueName: "Home Faq",
      displayName: "Home Faq"
    },
    {
      themeId: [5],
      component: FaqList,
      uniqueName: "Faq List",
      displayName: "FaqList"
    },
    // {
    //   themeId: [5],
    //   component: HomePhotoGallery,
    //   uniqueName: "Home Photo Gallery",
    //   displayName: "Home Photo Gallery"
    // },


    {
      themeId: [5],
      component: HomeWebStories,
      uniqueName: "Home Web Stories",
      displayName: "Home Web Stories"
    },
    
    {
      themeId: [5],
      component: BreakingNewsThreeTicker,
      uniqueName: "Breaking News Three Ticker",
      displayName: "Breaking News Three Ticker"
    },
    {
      themeId: [5],
      component: QuickViewThree,
      uniqueName: "Quick View Three",
      displayName: "Quick View Three"
    },
    {
      themeId: [5],
      component: Experts,
      uniqueName: "Experts",
      displayName: "Experts"
    },
    {
      themeId: [5],
      component: ListViewThree,
      uniqueName: "List View Three",
      displayName: "List View Three"
    },

    {
      themeId: [5],
      component: OtherListView,
      uniqueName: "OtherList View",
      displayName: "OtherList View"
    },
    {
      themeId: [5],
      component: HomeGridViewThree,
      uniqueName: "Home Grid View Three",
      displayName: "Home Grid View Three"
    },
    {
      themeId: [-1],
      component: WebStoriesThree,
      uniqueName: "WebStories Three",
      displayName: "WebStories Three"
    },
    {
      themeId: [5],
      component: WebStoriesListThree,
      uniqueName: "WebStoriesList Three",
      displayName: "WebStoriesList Three"
    },
  
    {
      themeId: [5],
      component: HeroLayoutThree,
      uniqueName: "HeroLayout Three",
      displayName: "HeroLayout Three"
    },
    // {
    //   themeId: [5],
    //   component: HeroLayoutHrThree,
    //   uniqueName: "HeroLayout Hr Three",
    //   displayName: "HeroLayout Hr Three"
    // },
    {
      themeId: [5],
      component: RhsHomeThree,
      uniqueName: "RhsHome Three",
      displayName: "RHS Layout Three"
    },
    {
      themeId: [5],
      component: RhsHomeOtherThree,
      uniqueName: "RhsHome Other Three",
      displayName: "RHS Layout Other Three"
    },
    {
      themeId: [5],
      component: GridViewThree,
      uniqueName: "GridView Three",
      displayName: "GridView Media Three"
    },
    // {
    //   themeId: [5],
    //   component: GridViewThree,
    //   uniqueName: "GridView Other Three",
    //   displayName: "GridView Other Three"
    // },
    {
      themeId: [5],
      component: MediaListThree,
      uniqueName: "MediaList Three",
      displayName: "MediaList Three"
    },
    {
      themeId: [5],
      component: PhotoListThree,
      uniqueName: "PhotoList Three",
      displayName: "PhotoList Three"
    },
    {
      themeId: [5],
      component: ArticleShowThree,
      uniqueName: "Article Show Three",
      displayName: "Article Show Three"
    },
    {
      themeId: [3, 4, 5],
      component: QuizShow,
      uniqueName: "Quiz Show",
      displayName: "Quiz Show"
    },
    {
      themeId: [3, 4, 5],
      component: SurveyShow,
      uniqueName: "Survey Show",
      displayName: "Survey Show"
    },
    {
      themeId: [5],
      component: VideoShowThree,
      uniqueName: "Video Show Three",
      displayName: "Video Show Three"
    },
    {
      themeId: [19,5],
      component: PodcastThree,
      uniqueName: "Podcast Three",
      displayName: "Podcast Three"
    },
    {
      themeId: [19,5],
      component: SlideShowThree,
      uniqueName: "Slide Show Three",
      displayName: "Slide Show Three"
    },
    {
      themeId: [5],
      component: FooterThree,
      uniqueName: "Footer Three",
      displayName: "Footer Three"
    },
    {
      themeId: [5],
      component: HeaderThree,
      uniqueName: "Header Three",
      displayName: "Header Three"
    },
    {
      themeId: [5],
      component: HeroLayoutwithHeader,
      uniqueName: "HeroLayout with Header",
      displayName: "HeroLayout with Header"
    },
    
  
    {
      themeId: [5],
      component: BreadCrumbsThree,
      uniqueName: "BreadCrumbs Three",
      displayName: "BreadCrumbs Three"
    },
    {
      themeId: [5],
      component: AddScripts,
      uniqueName: "Ad Scripts Three",
      displayName: "Ad Scripts Three "
    },
    
    {
      themeId: [5],
      component: AuthorShowThree,
      uniqueName: "Author Show Three",
      displayName: "Author Show Three"
    },
    {
      themeId: [5],
      component: StaticPageThree,
      uniqueName: "Static Page Three",
      displayName: "Static Page Three"
    },
    {
      themeId: [5],
      component: PageNotFoundThree,
      uniqueName: "PageNotFound Three",
      displayName: "PageNotFound Three"
    },
  
    {
      themeId: [5],
      component: ArticleShowThreeAmp,
      uniqueName: "Article Show Three Amp",
      displayName: "ArticleShow Three"
    },
    {
      themeId: [5],
      component: HeaderThreeAmp,
      uniqueName: "Header Three Amp",
      displayName: "Header Three"
    },
    {
      themeId: [5],
      component: FooterThreeAmp,
      uniqueName: "Footer Three Amp",
      displayName: "Footer Three"
    },
    {
      themeId: [5],
      component: BreadCrumbsThreeAmp,
      uniqueName: "Bread Crumbs Three Amp",
      displayName: "Bread Crumbs Three"
    },
    {
      themeId: [5],
      component: PhotoShowThreeAmp,
      uniqueName: "Photo Show Three Amp",
      displayName: "Photo Show Three"
    },
    {
      themeId: [5],
      component: VideoShowThreeAmp,
      uniqueName: "Video Show Three Amp",
      displayName: "Video Show Three"
    },
  ];


export const getComponentFromUniqueNameAndThemeId = (uniqueName, themeId) => {
  let components = theme_components.filter(o => o.uniqueName == uniqueName && (o.themeId.length == 0 || o.themeId.includes(themeId)));
  if (components && components.length == 1 && components[0]) {
    return components[0];
  }
}

export const getComponentFromNameAndThemeId = (displayName, themeId) => {
  let components = theme_components.filter(o => o.displayName == displayName && (o.themeId.length == 0 || o.themeId.includes(themeId)));
  //console.log("componentscomponentsdisplayName", components, displayName, themeId);
  if (components && components.length == 1 && components[0]) {
    return components[0];
  }
}

export default (editor, selectedPageTemplate) => {
  if (editor.BlockManager.all.length > 0) {
    editor.BlockManager.clear();
  }

  createComponentJson().then((componentJson) => {

    let themeId = localStorage.getItem("themeId");
    if (themeId) {
      themeId = parseInt(themeId);
    }

    const getUpdatedArr = (isAmp) => {
      let arr = [...componentJson];
      arr = isAmp ? arr.filter(val => val.name.includes("Amp")) : arr.filter(val => !val.name.includes("Amp"));
      return arr;
    }

    let isAMP = selectedPageTemplate && selectedPageTemplate.name && selectedPageTemplate.name.includes("Amp");
    let updateArr = getUpdatedArr(isAMP);

    for (var i = 0; i < updateArr.length; i++) {
      let comp = getComponentFromUniqueNameAndThemeId(updateArr[i].name, themeId);
      if (comp && comp.component.version) {
        updateArr[i].attributes['version'] = comp.version;
      }

      if (comp) {
        editor.Components.addType(comp.uniqueName, {
          extend: 'react-component',
          model: {
            defaults: {
              component: comp.component,
              stylable: true,
              resizable: true,
              hoverable: true,
              editable: true,
              draggable: true,
              droppable: true,
              attributes: updateArr[i].attributes,
              traits: updateArr[i].traits,
            },
          },
          isComponent: (el) => el.tagName === (updateArr[i] ? comp.uniqueName.toUpperCase() : "M360"),
        });

        editor.BlockManager.add(comp.uniqueName, {
          label: comp.displayName,
          content: { type: comp.uniqueName },
          category: CONSTANTS.COMPONENTS_CATEGORY.COMPONENTS,
          activate: true,
          media: `<img src=${updateArr[i].media} />`
        })
      }

    }

  });

}
